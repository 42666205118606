<template>
  <!-- error -->
  <div v-if="bankMandateStatus && errorState" class="card error">
    <div class="card-header">
      <div>
        <i class="material-icons circle">account_balance </i>
        <span class="title">{{ cardDetails.title }}</span>
      </div>
      <div>
        <button class="btn-error small-btn" disabled>
          <i class="material-icons">link_off</i>
          We couldn’t get the data
        </button>
      </div>
    </div>
    <div :class="[{ creditscore: card === 'creditscore' }, 'card-details']">
      <div class="upper-section">
        <img :src="cardDetails.imagePath" :alt="cardDetails.altText" />
        <div class="title">{{ cardDetails.imageText }}</div>
      </div>
      <div class="lower-section">
        {{ cardDetails.text }}
      </div>
      <BankIntegrationBtnTrueLayer :icon="true" classNames="small-btn button" btnLabel="Try again" :companyId="companyId" />
    </div>
    <div class="card-content">
      <div class="title">Why can’t get the data?</div>
      <div class="copy">{{ errorMessage }}</div>
    </div>
  </div>
  <!-- integrated -->
  <div v-else-if="bankMandateStatus === 'ProcessingCompleted'" class="card">
    <div :class="[{ integrated: bankMandateStatus === 'ProcessingCompleted' }, 'card-header']">
      <div>
        <i class="material-icons circle">account_balance </i>
        <span class="title">{{ cardDetails.title }}</span>
      </div>
      <div v-if="creditScoreDetails">
        <span class="date">Last sync: {{ date }}</span>
      </div>
    </div>
    <div class="details" v-if="creditScoreDetails && creditRatingData">
      <div class="creditscore-details">
        <img v-if="creditScoreDetails && creditScoreDetails.badge && creditScoreDetails.badge.badgeUrl" class="bagde" :src="creditScoreDetails.badge.badgeUrl" alt="badge" />
        <div class="grade">Grade</div>
        <div :class="gradeColor" class="grade-detail">{{ creditRatingData.rateDescription }}</div>
        <div class="copy" v-if="grade">{{ grade && grade.yourGradeExplainer }}</div>
        <div class="probability-container">
          <span class="probability">Your probability of default:</span>
          <span> {{ creditRatingData && creditRatingData.percentage }} %</span>
          <popover placement="top" trigger="hover click focus" class="popover">
            <em class="icon material-icons">info_outline</em>
            <template #popover>Probability of Default is the standard measure used by banks and funders in their credit process.</template>
          </popover>
        </div>
      </div>
    </div>
    <div v-else class="loader">
      <spinner :size="60" :loader="true" color />
      <p>We're fetching your data...</p>
    </div>
    <div class="card-buttons">
      <button class="small-btn white-btn" type="button" @click="unlinkBank(bankMandateList[0].bankMandateId)" v-promise-btn>Unlink account</button>
      <button @click="redirectTo()" class="btn small-btn btn-primary">See full health check</button>
    </div>
  </div>

  <!-- not integrated -->
  <div v-else class="card">
    <div class="card-header">
      <div>
        <i class="material-icons circle">account_balance </i>
        <span class="title"> {{ cardDetails.title }}</span>
      </div>
      <button v-if="card === 'creditscore'" class="small-btn btn-primary" :disabled="bankMandateStatus === null">
        <i class="material-icons">link_off</i>
        Not connected
      </button>
    </div>
    <div v-if="!bankMandateTimerId" :class="[{ creditscore: card === 'creditscore' }, 'card-details']">
      <div class="upper-section">
        <img :src="cardDetails.imagePath" :alt="cardDetails.altText" />
        <div class="title">{{ cardDetails.imageText }}</div>
      </div>
      <div class="lower-section">
        {{ cardDetails.text }}
      </div>
      <BankIntegrationBtnTrueLayer :icon="true" classNames="small-btn  small-btn-mobile button" :btnLabel="cardDetails.buttonText" :companyId="companyId" />
    </div>
    <spinner v-else :size="60" :loader="true" color />

    <div class="card-content">
      <div class="title">{{ cardDetails.contentTitle }}</div>
      <div class="copy">
        {{ cardDetails.contentDescription }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { creditScoreDetailsRoutine, creditScoreGetters } from '@/store/modules/creditScore/routines'
import { generateCreditReport } from '@/api/creditReport'
import gradeDetails from '@/features/creditScore/creditScoreGradeDetails.json'
import BankIntegrationBtnTrueLayer from '@/components/integrations/BankIntegrationBtnTrueLayer'

export default {
  name: 'BankIntegrationCard',
  components: {
    BankIntegrationBtnTrueLayer
  },
  data() {
    return { error: null, hasNoCreditReportRequest: false, currentBankMandateStatus: null }
  },
  props: {
    card: {
      type: String
    },
    bankMandateStatus: {
      type: String
    },
    bankMandateList: {
      type: Array
    },
    companyId: {
      type: String
    },
    cardDetails: {
      type: Object
    },
    unlinkBank: {
      type: Function
    },
    bankMandateTimerId: {
      type: Number
    }
  },
  methods: {
    ...mapActions({
      setCreditScoreDetails: creditScoreDetailsRoutine.TRIGGER
    }),
    redirectTo() {
      this.$router.push({ name: 'credit-score' })
    },
    async fetchCreditReport() {
      try {
        if (!this.hasNoCreditReportRequest) {
          await this.setCreditScoreDetails(this.companyId)
        }
        if (this.creditScoreDetails === null || this.hasNoCreditReportRequest) {
          await generateCreditReport(this.companyId)
          await this.setCreditScoreDetails(this.companyId)
        }
      } catch (error) {
        if (error?.response?.status === 422 && error?.response?.data && error?.response?.data?.code) {
          this.error = 'alreadyRegistered'
        } else if (error?.response?.data?.error === 'No Bank Accounts with Transactions found for Company') {
          this.error = 'hasNoBankAccount'
        } else if (error?.response?.status === 404) {
          this.hasNoCreditReportRequest = true
        } else {
          this.error = 'unknown'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      creditScoreDetails: creditScoreGetters.CREDIT_SCORE_DETAILS
    }),
    errorMessage() {
      if (this.error === 'alreadyRegistered') {
        return 'Your business has previously generated a report with our partner Credit Passport.'
      } else if (this.bankMandateStatus === 'AuthorisationExpired') {
        return 'The connection to your linked bank account has expired (connections last for 90 days).'
      } else if (this.error === 'hasNoBankAccount') {
        return 'Unfortunately, there aren’t enough transactions in your bank account to generate a report.'
      } else {
        return 'There’s been a technical hiccup somewhere - the best thing to do is try to link a bank account again, refresh the page, or come back later.'
      }
    },
    errorState() {
      return ['FetchingDataError', 'AuthorisationExpired', 'Failed'].includes(this.bankMandateStatus) || this.error !== null
    },
    creditRatingData() {
      return {
        rate: this.creditScoreDetails?.rating?.fullScore?.label,
        rateDescription: this.creditScoreDetails?.rating?.fullScore?.description,
        percentage: this.fixedPercentage
      }
    },
    grade() {
      return gradeDetails[this.creditRatingData.rate]
    },
    gradeColor() {
      switch (this.creditRatingData?.rate) {
        case 'A++':
          return 'grade-aaa'
        case 'A+':
          return 'grade-aa'
        case 'A':
          return 'grade-a'
        case 'B':
          return 'grade-b'
        case 'C':
          return 'grade-c'
        case 'D':
          return 'grade-d'
        case 'E':
          return 'grade-e'
        default:
          return 'primary'
      }
    },
    fixedPercentage() {
      const percentage = this.creditScoreDetails?.rating?.fullScore?.pd
      return (parseFloat(percentage) * 100).toFixed(2)
    },
    date() {
      const dt = new Date(this.creditScoreDetails?.created)
      return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()}`
    }
  },
  created() {
    if (this.bankMandateStatus === 'ProcessingCompleted') {
      this.fetchCreditReport()
    }
  },
  watch: {
    bankMandateStatus(value) {
      if (value === 'ProcessingCompleted') {
        this.fetchCreditReport()
      }
    },
    hasNoCreditReportRequest() {
      this.fetchCreditReport()
    }
  }
}
</script>
