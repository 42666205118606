<template>
  <div>
    <div v-if="currentStep === 'illion-start'" class="steps-box -large" data-testid="step-illion-start">
      <div class="copy">
        <h2 class="title">Let's get started with your funding journey</h2>
        <ul class="text">
          <li>
            We'll automatically collect 6 months bank statements from you right now, this cuts the time it takes to get the funds into your account by 87% in almost all cases.
          </li>
          <li>Our team will personally review your information and make sure this is the right product for your needs</li>
          <li>We will contact you within 1 business day to progress your application to the next step</li>
        </ul>
      </div>
      <button class="btn btn-secondary" @click="startJourney">
        <div class="btn-icon"></div>
        Start your application
      </button>
      <span class="disclaimer">No credit checks and no formal applications are sent to lenders at this stage, we match you to the best products first!</span>
    </div>
    <IllionIFrame v-else-if="currentStep === 'iframe'" data-testid="step-iframe" :company-id="companyId" :origin="mode" @submit_all="iFrameEnd" />
    <div v-else-if="currentStep === 'illion-end'" class="steps-box" data-testid="step-illion-end">
      <img src="@/assets/images/icons/icon-check.svg" alt="Credit Score" width="auto" height="40" />
      <div class="copy">
        <h2 class="title">You have already submited your details</h2>
        <p class="text">Start an application for this product</p>
      </div>
      <button class="btn btn-secondary" @click="startApplication">Start an application</button>
    </div>
    <div v-else-if="currentStep === 'applied'" class="steps-box" data-testid="step-applied">
      <img src="@/assets/images/icons/icon-check.svg" alt="Credit Score" width="auto" height="40" />
      <div class="copy">
        <h2 class="title">You've successfully submitted your details. We'll be in touch soon</h2>
        <p class="text">You can apply for more than one product, go <a class="link" @click.prevent="closePageOverlay">here</a> to check out more of your matches</p>
      </div>
    </div>
    <div v-else-if="currentStep === 'integrated'" class="steps-box" data-testid="step-applied">
      <img src="@/assets/images/icons/icon-check.svg" alt="Credit Score" width="auto" height="40" />
      <div class="copy">
        <h2 class="title">You've successfully submitted your details. We'll be in touch soon</h2>
        <p class="text">You can now apply for our loan products, go <router-link :to="{ name: 'loan-matches' }" class="link">here</router-link> to check out your matches</p>
      </div>
    </div>
  </div>
</template>

<script>
import IllionIFrame from '@/features/illion/IllionIFrame'
import { amplitudeEvents } from '@/utils/constants'

export default {
  components: {
    IllionIFrame
  },
  data() {
    return {
      showIframe: false,
      currentStep: this.status || 'illion-start',
      amplitudeEvents
    }
  },
  props: {
    status: {
      type: String,
      required: true
    },
    companyId: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  methods: {
    startJourney() {
      this.currentStep = 'iframe'
      this.$ma.trackEvent({
        eventType: amplitudeEvents.Illion.START_APPLICATION,
        eventProperties: {
          'Event Origin': this.mode || 'No origin specified'
        }
      })
    },
    iFrameEnd() {
      if (this.mode === 'integration page') {
        this.currentStep = 'integrated'
        return
      }
      this.$emit('illion-start-application')
      this.currentStep = 'applied'
    },
    startApplication() {
      this.$emit('illion-start-application')
      this.currentStep = 'applied'
    },
    closePageOverlay() {
      this.$emit('close-overlay')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/swoop/_variables.scss';

.steps-box {
  height: auto;
  min-height: 96px;
  color: white;
  display: flex;
  align-items: center;
  padding: 25px;
  border-radius: 8px;
  background-image: url('../../assets/images/triangles.svg'), linear-gradient(322deg, var(--color-secondary-500), var(--color-primary-500));
  background-repeat: no-repeat;
  background-position: left 50%;
  margin-bottom: 2rem;
  &.-large {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: auto;
    min-height: 358px;
    padding-left: 3rem;
    .copy {
      margin-left: 0;
      line-height: 2;
    }
    .title {
      font-size: 40px;
      margin-top: 1rem;
    }
    .text {
      padding: 1rem;
    }
  }

  .copy {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 1rem;
  }
  .link {
    color: white;
    font-weight: bold;
    &:visited {
      color: white;
    }
  }
  .title {
    color: white;
    font-size: 24px;
    font-family: $font-primary;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 5px;
  }
  .text {
    margin-bottom: 0;
    text-align: left;
    font-size: 14px;
  }

  .disclaimer {
    padding-bottom: 0;
  }

  .btn {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    img {
      margin-right: 1rem;
    }
  }
  .btn-icon {
    background-color: var(--color-primary-500);
    -webkit-mask: url('../../assets/images/icons/icons_banking.svg') no-repeat center;
    mask: url('../../assets/images/icons/icons_banking.svg') no-repeat center;
    width: 22px;
    height: 22px;
    margin-right: 1rem;
  }
}
</style>
