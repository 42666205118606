<template>
  <!-- error -->
  <div v-if="forwardAIIntegration && forwardAIIntegration.status && error" class="card error">
    <div class="card-header">
      <div>
        <i class="material-icons circle">auto_graph </i>
        <span class="title">{{ cardDetails.title }}</span>
      </div>
      <div>
        <button :class="[{ 'btn-error': error }, 'small-btn']" :disabled="error">
          <i class="material-icons">link_off</i>
          We couldn’t get the data
        </button>
      </div>
    </div>
    <div :class="[{ cashflow: card === 'cashflow' }, 'card-details']">
      <div class="upper-section">
        <img :src="cardDetails.imagePath" :alt="cardDetails.altText" />
        <div class="title">{{ cardDetails.imageText }}</div>
      </div>
      <div class="lower-section">
        {{ cardDetails.text }}
      </div>
      <ForwardAIIntegrationBtn :icon="true" classNames="small-btn button" btnLabel="Try again" :companyId="companyId" />
    </div>
    <div class="card-content">
      <div class="title">Why can’t get the data?</div>
      <div class="copy">There’s been a technical hiccup somewhere - the best thing to do is try to link the accountancy software again, refresh the page, or come back later.</div>
    </div>
  </div>
  <!-- integrated -->
  <div v-else-if="routeNameAfterIntegration === 'forwardaiCallback' || (forwardAIIntegration && forwardAIIntegration.status === 'connected')" class="card">
    <div :class="[{ integrated: forwardAIIntegration.status === 'connected' }, 'card-header']">
      <div>
        <i class="material-icons circle">auto_graph </i>
        <span class="title">{{ cardDetails.title }}</span>
        <span class="date" v-if="cashFlowDetails && cashFlowDetails.lastAvailableDate">Last sync: {{ cashFlowDetails.lastAvailableDate | dateFormat}} </span>
      </div>
      <div>
        <button class="small-btn white-btn" @click="fetchData()">
          Re-sync
          <i class="material-icons">sync</i>
        </button>
      </div>
    </div>
    <div class="details" v-if="cashFlowDetails && cashFlowDetails.lastAvailableDate !== '' && !loading">
      <div class="cashflow-details">
        <div class="item-wrapper">
          <div class="label">
            Cash available
            <i class="material-icons info">info</i>
          </div>
          <div>{{ cashFlowDetails.lastAvailableDate | dateFormat }}</div>
        </div>
        <div class="value">{{ cashFlowDetails.cashToday | commarize | currency }}</div>
      </div>
      <div class="cashflow-details">
        <div class="item-wrapper">
          <div class="label">
            Invoices
            <i class="material-icons info">info</i>
          </div>
          <div v-if="cashFlowDetails.invoiceNumber">{{ cashFlowDetails.invoiceNumber }} Invoices</div>
        </div>
        <div class="value">{{ cashFlowDetails.invoices | commarize | currency }}</div>
      </div>
      <div class="cashflow-details">
        <div class="item-wrapper">
          <div class="label">
            Bills
            <i class="material-icons info">info</i>
          </div>
          <div v-if="cashFlowDetails.billsNumber">{{ cashFlowDetails.billsNumber }} Bills</div>
        </div>
        <div class="value">{{ cashFlowDetails.bills | commarize | currency }}</div>
      </div>
    </div>
    <div v-else class="loader">
      <spinner :size="60" :loader="true" color />
      <p>We're fetching your data...</p>
    </div>
    <div class="card-buttons">
      <div />
      <button @click="redirectTo()" class="btn small-btn btn-primary">See cash flow & forecasting</button>
    </div>
  </div>
  <!-- not integrated -->
  <div v-else class="card">
    <div class="card-header">
      <div>
        <i class="material-icons circle">auto_graph </i>
        <span class="title"> {{ cardDetails.title }}</span>
      </div>
      <button class="small-btn btn-primary" :disabled="forwardAIIntegration && forwardAIIntegration.status !== 'connected'">
        <i class="material-icons">link_off</i>
        Not connected
      </button>
    </div>
    <div :class="[{ cashflow: card === 'cashflow' }, 'card-details']">
      <div class="upper-section">
        <img :src="cardDetails.imagePath" :alt="cardDetails.altText" />
        <div class="title">{{ cardDetails.imageText }}</div>
      </div>
      <div class="lower-section">
        {{ cardDetails.text }}
      </div>
      <ForwardAIIntegrationBtn :icon="true" classNames="small-btn small-btn-mobile button" :btnLabel="cardDetails.buttonText" :companyId="companyId" />
    </div>
    <div class="card-content">
      <div class="title">{{ cardDetails.contentTitle }}</div>
      <div class="copy">
        {{ cardDetails.contentDescription }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { cashFlowRoutine, cashFlowGetters } from '@/store/modules/cashFlow/routines'
import ForwardAIIntegrationBtn from '@/components/integrations/ForwardAIIntegrationBtn'
import { forwardAIGetters, forwardAIIntegrationRoutine } from '@/store/modules/forwardAI/routines'
export default {
  name: 'AccounancyIntegrationCard',
  components: {
    ForwardAIIntegrationBtn
  },
  data() {
    return {
      error: false,
      accountancyIntegrationTimerId: null,
      loading: false,
      limitForStatus: 200,
      limitForCashflow: 200
    }
  },
  props: {
    card: {
      type: String
    },
    companyId: {
      type: String,
      required: true
    },
    forwardAIIntegration: {
      type: Object,
      default: () => ({})
    },
    cardDetails: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      cashFlowDetails: cashFlowGetters.CASH_FLOW_DETAILS,
      forwardAIError: forwardAIGetters.FORWARD_AI_ERROR,
      routeNameAfterIntegration: forwardAIGetters.ROUTE_NAME_AFTER_INTEGRATION
    })
  },
  methods: {
    ...mapActions({
      setCashFlowDetails: cashFlowRoutine.TRIGGER,
      getForwardAIIntegration: forwardAIIntegrationRoutine.TRIGGER
    }),
    redirectTo() {
      this.$router.push({ name: 'cashflow-forecast' })
    },
    async fetchData() {
      if (this.forwardAIIntegration?.status === 'connected') {
        try {
          if (this.limitForCashflow > 0) {
            if (this.cashFlowDetails === null) {
              this.limitForCashflow--
              await this.setCashFlowDetails(this.companyId)
              await this.fetchData()
            } else if (this.cashFlowDetails?.lastAvailableDate === '') {
              this.limitForCashflow--
              await new Promise(resolve => setTimeout(resolve, 1000))
              await this.setCashFlowDetails(this.companyId)
              await this.fetchData()
            } else {
              await this.setCashFlowDetails(this.companyId)
            }
          }
        } catch (error) {
          this.error = error?.response.status === 500
        }
      }
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    forwardAIIntegration(value) {
      this.forwardAIIntegration = value
      if (this.routeNameAfterIntegration === 'forwardaiCallback' && this.forwardAIIntegration?.status === 'pending' && this.limitForStatus > 0) {
        this.loading = true
        this.limitForStatus--
        return new Promise(resolve => {
          this.accountancyIntegrationTimerId = setTimeout(() => {
            resolve(this.getForwardAIIntegration(this.companyId))
          }, 1000)
        })
      }
      if (this.forwardAIIntegration?.status === 'connected') {
        this.loading = false
        this.fetchData()
      }
    }
  }
}
</script>
