<template>
  <div class="container">
    <h2 class="integrations-title">Integrations</h2>
    <p class="description">Link your financial data to find and apply for funds quicker and calculate savings.</p>
    <div class="card-container">
      <template v-if="$config.whitelabel.features.linkAccountancy">
        <AccountancyIntegrationCard
          :cardDetails="cashflowCardDetails"
          :companyId="companyId"
          :forwardAIIntegration="forwardAIIntegration"
          card="cashflow"
        />
      </template>
      <template v-if="$config.whitelabel.features.linkBankAccount">
        <BankIntegrationCard
          :cardDetails="creditscoreCardDetails"
          :bankMandateList="bankMandateList"
          :bankMandateStatus="bankMandateStatus"
          :companyId="companyId"
          :unlinkBank="unlinkBank"
          :bankMandateTimerId="bankMandateTimerId"
          card="creditscore"
        />
      </template>
    </div>
    <div v-if="$config.whitelabel.features.illionEnabled" class="illion-integration">
      <IllionJourneySteps :company-id="companyId" :status="illionStatus()" mode="integration page" />
    </div>
    <FooterOutgoingLinks />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { deleteBankMandate } from '@/api/bankMandate'
import { authGetters } from '@/store/modules/auth/routines'
import { bankMandateListRoutine, bankGetters } from '@/store/modules/bank/routines'
import IllionJourneySteps from '@/features/illion/IllionJourneySteps'
import { illionGetters, illionIframeRoutine, illionBankstatementsRoutine } from '@/store/modules/illion/routines'
import { getIframeUrl, getBankStatements } from '@/api/illion'
import illionAllowedTenants from '@/mixins/illionAllowedTenants'
import { sidebarGetters } from '../../store/modules/sidebar/routines'
import { toastDefaultOptions } from '../../config/vue-toast'
import FooterOutgoingLinks from '@/components/FooterOutgoingLinks'
import BankIntegrationCard from '@/features/integrations/bankIntegrationCard'
import AccountancyIntegrationCard from '@/features/integrations/accountancyIntegrationCard'
import { forwardAIIntegrationRoutine, forwardAIGetters } from '@/store/modules/forwardAI/routines'
import accountingIntegration from '@/mixins/accountingIntegration'
export default {
  name: 'Integrations',
  components: { BankIntegrationCard, AccountancyIntegrationCard, IllionJourneySteps, FooterOutgoingLinks },
  mixins: [illionAllowedTenants, accountingIntegration],
  data() {
    return {
      limit: 60,
      bankMandateTimerId: null,
      isUnlinkingBank: false,
      forwardAIIntegrationTimerId: null,
      creditscoreCardDetails: {
        title: 'Bank account ',
        imagePath: require('@/assets/images/credit-passport/credit_score_bagde.svg'),
        altText: 'credit passport image',
        imageText: 'Credit Passport',
        text: "Credit Passport® is the secure way to see your business credit score. It doesn't impact their credit profile and you're in total control of the data you share. ",
        buttonText: 'Link your bank account data',
        contentTitle: 'How will banking data be used?',
        contentDescription:
          'We use Open Banking data to calculate your Credit Passport. This is a secure, regulated way to see how the global finance system sees thier business. Open Banking is authorised and regulated by the Financial Conduct Authority. At the moment, our Open Banking software doesn’t support every bank - but we’re working on it.',
        redirect: { name: 'credit-health-check' }
      },
      cashflowCardDetails: {
        title: 'Accounting software',
        imagePath: require('../cashflowForecast/assets/images/cashflow-and-forecasting-group.svg'),
        altText: 'cashflow and forecasting image',
        imageText: 'Cash Flow & Forecasting',
        text: 'Better understand your cashflow by integrating their accounting software. You’ll have access to up-to-date financial data, making it easy to make the right decisions for their future.',
        buttonText: 'Link your accountancy software',
        contentTitle: 'Why connect accountancy software?',
        contentDescription:
          'It means the whole funding process becomes easier and far quicker - we automatically find the information you’d normally fill in manually! You can rest assured any data you share is safe with Swoop, as we’re regulated by the Financial Conduct Authority.',
        redirect: { name: 'credit-health-check' }
      }
    }
  },
  mounted() {
    if (this.isAllowedTenant(this.tenant)) {
      getIframeUrl(this.companyId)
        .then(res => {
          this.setIllionIframeUrl(res.data)
        })
        .catch(e => console.error('Error when fetching iFrame url:', e))
      getBankStatements(this.companyId)
        .then(res => {
          this.setIllionBankStatements(res.data)
        })
        .catch(e => console.error('Error when fetching Bankstatements:', e))
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER,
      companyId: companyGetters.COMPANY_ID,
      bankMandateList: bankGetters.BANK_MANDATE_LIST,
      bankMandateStatus: bankGetters.BANK_MANDATE_STATUS,
      forwardAIIntegration: forwardAIGetters.FORWARDAI_INTEGRATION,
      isSideBarOpen: sidebarGetters.SIDEBAR_STATUS,
      bankStatementsReport: illionGetters.BANK_STATEMENTS
    }),
    tenant() {
      return this.$config.whitelabel.country.defaultCountry
    },
    integrationsEnabled() {
      return (
        this.$config.whitelabel.features.integrationsEnabled &&
        (this.$config.whitelabel.features.linkAccountancy || this.$config.whitelabel.features.linkBankAccount || this.$config.whitelabel.features.illionEnabled)
      )
    }
  },
  methods: {
    ...mapActions({
      getBankMandateList: bankMandateListRoutine.TRIGGER,
      getForwardAIIntegration: forwardAIIntegrationRoutine.TRIGGER,
      setIllionIframeUrl: illionIframeRoutine.TRIGGER,
      setIllionBankStatements: illionBankstatementsRoutine.TRIGGER
    }),
    init() {
      if (this.companyId && this.$config.whitelabel.features.linkBankAccount) {
        return Promise.all([this.requestForwardAIIntegration(), this.requestBankMandate()])
      } else if (this.companyId && !this.$config.whitelabel.features.linkBankAccount) {
        return this.requestForwardAIIntegration() // mixin
      }
    },
    async requestBankMandate() {
      try {
        await this.getBankMandateList(this.companyId) // FetchingData, FetchingDataCompleted, ProcessingCompleted, Failed
      } catch (err) {
        this.$toasted.show('Bank integration state is currently not available', { ...toastDefaultOptions, type: 'error', icon: 'error' })
        return
      }
      if (!this.user || !this.bankMandateStatus || this.bankMandateStatus === 'ProcessingCompleted' || this.bankMandateStatus === 'Failed') {
        return
      }
      if (this.limit > 0) {
        return new Promise(resolve => {
          this.limit--
          this.bankMandateTimerId = setTimeout(() => {
            resolve(this.requestBankMandate())
          }, 2000)
        })
      }
    },
    unlinkBank(bankMandateId) {
      this.isUnlinkingBank = true
      return deleteBankMandate(bankMandateId)
        .then(this.requestBankMandate)
        .then(() => {
          this.$toasted.show('Account unlink is successful.', { ...toastDefaultOptions, type: 'success', icon: 'error_outline' })
          this.isUnlinkingBank = false
        })
        .catch(e => {
          this.$toasted.show('Account unlink is not successful.', { ...toastDefaultOptions, type: 'error', icon: 'error' })
          this.isUnlinkingBank = false
        })
    },
    illionStatus() {
      return this.bankStatementsReport.length ? 'integrated' : 'illion-start'
    }
  },
  beforeMount() {
    if (!this.integrationsEnabled) {
      this.$router.push({ name: 'dashboard' })
    }
    this.init()
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isUnlinkingBank || to.name === 'login') {
      next()
    } else {
      this.$modal.show('unlinkingBankModal')
    }
  },
  beforeDestroy() {
    clearTimeout(this.bankMandateTimerId)
    clearTimeout(this.forwardAIIntegrationTimerId)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';

.illion-integration {
  margin-top: 2rem;
}
.container {
  padding: 50px;
  @media only screen and (max-width: $breakpoint-sm-max) {
    padding: 10px;
  }
  .description {
    margin-bottom: 60px;
    @media only screen and (max-width: $breakpoint-sm-max) {
      margin-bottom: 30px;
      font-size: 14px;
    }
  }
}
.card-container {
  display: flex;
  // justify-content: space-around;
  gap: 30px;
  @media only screen and (max-width: $breakpoint-xs-max) {
    flex-direction: column;
    gap: 20px;
  }
}

/deep/.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  gap: 24px;
  width: 100%;
  width: 500px;
  background: $color-white;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
  border-radius: 16px;
  @media only screen and (max-width: $breakpoint-xs-max) {
    padding: 20px;
    width: 100%;
  }
  .card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    div:last-child {
      @media only screen and (max-width: $breakpoint-sm-max) {
        justify-content: space-between;
        display: flex;
      }
    }

    .circle {
      background-color: $color-primary;
      border-radius: 50%;
      padding: 5px;
      font-size: 18px;
      color: $color-white;
      margin-right: 5px;
    }
    .title {
      color: $color-primary;
      font-weight: bold;
      font-size: 16px;
      @media only screen and (max-width: $breakpoint-sm-max) {
        font-size: 14px;
      }
    }
    .date {
      font-size: 12px;
      margin-left: 20px;
    }
  }
  .integrated {
    @media only screen and (max-width: $breakpoint-sm-max) {
      flex-direction: column;
      align-items: flex-start;
    }
    > div {
      @media only screen and (max-width: $breakpoint-sm-max) {
        margin-bottom: 10px;
      }
    }
  }
  .card-details {
    border-radius: 10px;
    padding: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: relative;
    color: $color-white;
    opacity: 0.9;
    @media only screen and (max-width: $breakpoint-xs-max) {
      padding: 20px;
    }
    &.cashflow {
      background-image: url(../../assets/images/cashflow_background.svg);
    }
    &.creditscore {
      background-image: url(../../assets/images/creditscore_background.svg);
    }

    .button {
      &:hover {
        background-color: var(--color-main-hover);
      }
    }
    .upper-section {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      img {
        width: 30%;
        height: 30%;
        margin-right: 30px;
      }
      .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 26px;
        width: 50%;
      }
    }
    .lower-section {
      font-size: 15px;
      line-height: 21px;
      letter-spacing: -0.004em;
      margin-bottom: 20px;
    }
  }
  .details {
    border: 1px solid var(--color-primary-100);
    border-radius: 16px;
    width: 100%;
    .cashflow-details {
      border-bottom: 1px solid var(--color-primary-100);
      padding: 30px;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: $breakpoint-xs-max) {
        padding: 20px;
        align-items: center;
        height: 100px;
      }
      .item-wrapper {
        .label {
          font-size: 16px;
          line-height: 19px;
          font-weight: bold;
          .info {
            font-size: 16px;
          }
        }
        > div {
          font-size: 14px;
        }
      }
      .value {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .creditscore-details {
      padding: 20px;
      .bagde {
        width: 50%;
        height: 50%;
      }
      .grade {
        color: $color-primary;
        font-weight: bold;
        margin: 20px 0;
      }
      .grade-detail {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: -0.4px;
        line-height: 21px;
        margin: 20px 0;
      }
      .grade-aaa {
        color: var(--color-creditscore-grade-AAA);
      }
      .grade-aa {
        color: var(--color-creditscore-grade-AA);
      }
      .grade-a {
        color: var(--color-creditscore-grade-A);
      }
      .grade-b {
        color: var(--color-creditscore-grade-B);
      }
      .grade-c {
        color: var(--color-creditscore-grade-C);
      }
      .grade-d {
        color: var(--color-creditscore-grade-D);
      }
      .grade-e {
        color: var(--color-creditscore-grade-E);
      }
      .copy {
        font-size: 14px;
        margin: 20px 0;
      }
      .probability-container {
        display: flex;
        align-items: center;
        .probability {
          font-size: 14px;
          font-weight: bold;
          margin-right: 5px;
        }
        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    P {
      margin-top: 40px;
      font-weight: bold;
    }
  }
  .card-content {
    .title {
      color: $color-primary;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
    }
    .copy {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .card-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .white-btn {
    color: $color-primary;
  }
  .small-btn {
    border-radius: 1000px;
    font-size: 14px;
    padding: 5px 15px;
    @media only screen and (max-width: $breakpoint-xs-max) {
      white-space: nowrap;
    }
    &:disabled {
      &:hover,
      &:focus {
        color: $color-primary-button-disabled-text;
        background-color: $color-primary-button-disabled;
        cursor: not-allowed;
      }
    }
    i {
      font-size: 13px;
      margin-right: 3px;
    }
    &:hover {
      background-color: var(--color-main-hover);
      color: $color-white;
    }
    &-mobile {
      padding: 5px 35px;
      @media only screen and (max-width: $breakpoint-xs-max) {
        width: 100%;
      }
      .material-icons {
        margin-left: -10px;
      }
    }
  }
  .material-icons {
    vertical-align: middle;
  }
}
.error {
  border: 1px solid var(--color-error-500);
}
/deep/ .btn-error {
  background-color: var(--color-error-500);
  color: $color-white;
}
</style>
